import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Ubuntu', sans-serif",
        heading: "'Merriweather', serif",
        monospace: "'Merriweather', serif",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#424040',
        background: '#fff',
        grey: '#9c9c9c',
        primary: '#ff2f00',
        secondary: '#f3bf00',
        muted: '#f9f9f9',
    },
}